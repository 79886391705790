<template>
  <div>
    <div v-if="showing" :key="showing">
      <fieldsValues
        v-if="typeof value != 'undefined'"
        :fieldAttributes="localFieldAttributes"
        :field="field"
        :templateContent="result"
        v-on="$listeners"
        :value="value"
        :valueLine="valueLine"
      ></fieldsValues>
    </div>
  </div>
</template>
<script>
import fieldsValues from "@/commonComponents/fieldsValues.vue";
export default {
  components: { fieldsValues },
  props: ["result", "field", "fieldAttributes", "value", "valueLine"],
  data() {
    return { localFieldAttributes: false };
  },
  watch: {
    fieldAttributes() {
      this.calculateFieldAttributes();
    },
  },
  created: function () {
    this.calculateFieldAttributes();
  },

  methods: {
    calculateFieldAttributes() {
      let localFieldAttributes = JSON.parse(
        JSON.stringify(this.fieldAttributes)
      );
      this.$set(
        localFieldAttributes.fieldAttrInput,
        "class",
        localFieldAttributes.fieldAttrInput.class + " required"
      );
      this.$set(localFieldAttributes, "type", "select");
      this.localFieldAttributes = localFieldAttributes;
    },
  },
  computed: {
    showing() {
      return (
        this.valueLine.c_type == "DIAMETER" &&
        typeof this.valueLine.templateId != "undefined"
      );
    },
  },
};
</script>